.panel {
    padding-top: 70px;
    .title {
        font-weight: 700;
        font-size: 28px;
    }
    .description {
        margin-top: 8px;
        color: #6b7280;
        margin-bottom: 43px;
    }
    .basicInfo {
        margin-bottom: 14px;
        font-weight: 600;
        font-size: 18px;
    }
    .upload {
        display: flex;
        justify-content: center;
    }
}
