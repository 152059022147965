.green-tabs .ant-tabs-tab {
    padding: 4px 0 !important;
}

.green-tabs .ant-tabs-tab div {
    padding: 8px;
}

.green-tabs .ant-tabs-tab.ant-tabs-tab-active div {
color: #fff !important;
background: #25D076 !important;
border: 1px solid #25D076;
border-radius: 8px;
}

.green-tabs.ant-tabs .ant-tabs-tab:hover {
    color: #25d076 !important;
}

.green-tabs .ant-tabs-nav-wrap {
    justify-content: center;
}

.green-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: transparent;
}