.panel {
    padding-top: 70px;
    .image {
        text-align: center;
    }
    .title {
        margin-top: 44px;
        text-align: center;
    }
    .description {
        padding-top: 16px;
    }
    .buttons {
        margin-top: 90px;
    }
}
