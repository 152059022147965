.search {
    margin-top: 40px;
}

.featured {
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: 700;
}

.allCourses {
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: 700;
    .filter {
        float: right;
        & > span:nth-of-type(1) {
            color: transparent;
        }
        & > span:nth-of-type(2) {
            color: #6b7280;
        }
    }
}

.card {
    border: 1px solid #f3f4f6 !important;
    border-radius: 12px;
    .course {
        padding: 12px;

        .image {
            border-radius: 6px;
            height: 100%;
            width: 100%;
        }

        .info {
            padding-left: 16px;
            .title {
                font-weight: 700;
                font-size: 16px;
            }
            .details {
                display: flex;
                justify-content: space-between;
                font-weight: 700;
                font-size: 12px;
                color: #6b7280;
            }
        }
    }
}

.featuredCard {
    .image {
        border-radius: 12px;
        width: 100%;
    }

    .title {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .details {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        font-size: 12px;
        color: #6b7280;
    }
}
