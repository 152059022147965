.panel {
    .courseName {
        font-weight: 800;
        font-size: 20px;
        margin: 17px 0 17px 0;
        text-align: center;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        .image {
            margin-bottom: 27px;
        }
    }

    .tabs {
        // To change tabs font, margin, background, hidde bottom line
        & > div:nth-child(1) {
            background: #f9fafb;
            border-radius: 10px;
            color: #9ca3af;
            font-weight: 600;
        }

        & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(1) {
            margin: 0;
            padding-left: 4px !important;
        }
        & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(2) {
            margin: 0;
        }
        & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(3) {
            margin: 0;
        }
        & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(4) {
            background: none;
        }

        & > div:nth-child(1) {
            margin: 0 0 35px 0;
            &::before {
                border-bottom: none;
            }
        }
    }

    .sectionTitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;
    }

    .sectionDesc {
        margin-bottom: 27px;
        font-size: 15px;
        color: #6b7280;
    }

    .author {
        border: 1px solid #dddfe3;
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 27px;

        .image {
            border-radius: 6px;
            height: 100%;
            width: 100%;
        }

        .info {
            padding-left: 16px;
            .name {
                font-weight: 700;
            }
            .details {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: #6b7280;
                margin-top: 6px;
                .icon {
                    color: transparent;
                }
            }
        }
    }

    .lessons {
        margin-bottom: 27px;
        border-radius: 12px;
        border: 1px solid #dddfe3;
        & > div:nth-child(1) > div:nth-child(1) {
            border-radius: 12px !important;
            background: #fff;
        }
        & > div:nth-child(1) > div:nth-child(1) > div {
            display: none !important;
        }
        & > div:nth-child(1) > div:nth-child(2) {
            border-bottom-left-radius: 12px !important;
            border-bottom-right-radius: 12px !important;
            background: #fff;
        }
        & > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
            padding-top: 0 !important;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .lesson {
                font-size: 15px;
                font-weight: 600;
            }
            .arrowDown {
                font-size: 20px;
                color: transparent;
            }
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 18px;
            .playName {
                display: flex;
                align-items: center;
                .play {
                    font-size: 18px;
                    margin-right: 12px;
                }
                .name {
                    font-size: 14px;
                }
            }
            .duration {
                font-size: 12px;
                color: #9ca3af;
            }
        }
    }

    .buttons {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .fav {
            height: 56px;
            width: 56px;
            border: 1px solid #01353b;
            border-radius: 12px;
            padding: 16px;
            margin-right: 12px;
        }
        .heart {
            font-size: 20px;
        }
    }
}
