.panel {
    .terms {
        margin-top: 10px;
    }
    .select {
        & > div:nth-child(1) {
            height: 100% !important;
            padding: 16px !important;
            border-radius: 12px;
        }
    }
}
