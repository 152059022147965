.header {
    color: #fff;
    font-weight: 600;
}

.title {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    padding: 0 40px;
}

.submission {
    margin-top: 22px;
    color: #111827;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid #dddfe3;
    border-radius: 12px;
    margin-top: 13px;
    .file {
        display: flex;
        align-items: center;
        .fileName {
            margin-left: 12px;

            .name {
                font-weight: 700;
                font-size: 14px;
                line-height: 160%;
            }
            .submitted {
                color: #6b7280;
                font-size: 12px;
            }
        }
    }
    .download {
        font-size: 20px;
        color: transparent;
    }
    .icon {
        font-size: 24px;
    }
}

.lesson {
    margin-top: 13px;
    font-weight: 600;
    .workplace {
        font-weight: 600;
        font-size: 15px;
        line-height: 160%;
        padding: 16px;
        border-bottom: 1px solid #eeeff2;
    }
    .video {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .senior {
            font-size: 13px;
            line-height: 160%;
            color: #111827;
            padding: 16px;
        }
        .time {
            color: #9ca3af;
            font-size: 12px;
        }
    }
}

.select {
    margin-top: 13px;
    & > div:nth-child(1) {
        height: 100% !important;
        padding: 16px !important;
        border-radius: 12px;
    }
}

.textArea {
    height: 100% !important;
    padding: 16px !important;
    border-radius: 12px;
}
