.pageTitle {
    font-weight: 600;
    line-height: 150%;
    color: #111827;
    margin-left: -20px;
}

.icon {
    font-size: 24px;
}

.sectionTitle {
    margin-top: 60px;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: #111827;
}

.sectionDesc {
    margin-bottom: 24px;
    color: #6b7280;
    line-height: 150%;
}

.category {
    & > div:nth-child(1) {
        height: 56px !important;
        padding: 12px 15px !important;
        border-radius: 12px !important;
    }
    & > div:nth-child(2) {
        height: 56px !important;
        padding: 12px 15px !important;
        border-radius: 12px !important;
    }
}

.courseDesc {
    width: 354px;
    height: 204px !important;
}

.buttons {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cancel {
        height: 56px;
        width: 97px;
        border: 1px solid #01353b;
        border-radius: 12px;
        padding: 15px 24px;
        margin-right: 12px;
    }
}

.dragger {
    & > div:nth-child(1) {
        background: #edeff0 !important;
        border-radius: 12px;
        padding: 66px 145px;
    }
    .folder {
        width: 74px !important;
        height: 74px !important;
    }
}

.fileType {
    padding: 0 30px;
    margin-top: 15px;
    text-align: center;
    color: #6b7280;
    font-size: 14px;
    line-height: 160%;
}
