.icon {
    font-size: 24px;
}

.title {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 28px;
}

.description {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 25px;
    line-height: 150%;
    color: #6b7280;
}

.select {
    margin-top: 13px;
    & > div:nth-child(1) {
        height: 100% !important;
        padding: 16px !important;
        border-radius: 12px;
    }
}

.textArea {
    height: 100% !important;
    padding: 16px !important;
    border-radius: 12px;
}

.date {
    height: 100% !important;
    width: 100% !important;
    padding: 16px !important;
    border-radius: 12px;
}

.materials {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    border: 1px dashed #cbcbcb;
    border-radius: 12px;
    padding: 8px;
    gap: 2px;
    .text {
        font-size: 15px;
        color: #111827;
        line-height: 150%;
    }
}

.buttons {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cancel {
        height: 56px;
        border: 1px solid #01353b;
        border-radius: 12px;
        padding: 15px 24px;
        margin-right: 12px;
    }
}
