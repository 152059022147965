.panel {
    .courseName {
        font-weight: 800;
        font-size: 20px;
        margin: 17px 0 17px 0;
        text-align: center;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        .image {
            margin-bottom: 27px;
        }
    }

    .tabs {
        margin-bottom: 2rem;
        & > div:before {
            display: none;
        }
        & > div:nth-child(1) {
            background: #fff;
            border-radius: 8px;
            color: #6b7280;
            margin: 0 0 35px 0;
        }
        & > div:nth-of-type(1) > div > div > div {
            width: 105px;
            margin-left: 0 !important;
            margin-right: 0.8rem !important;
            height: 38px;
            border-radius: 8px !important;
            //background: #25d076;
            border: 1px solid #eeeff2;
            & > div {
                font-size: 14px;
                text-align: center;
                line-height: 160%;
                width: 100%;
                color: #6b7280;
            }
            &:hover > div {
                color: #fff;
            }
        }
    }

    .sectionTitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;
    }

    .sectionDesc {
        margin-bottom: 27px;
        font-size: 15px;
        color: #6b7280;
    }

    .author {
        border: 1px solid #dddfe3;
        border-radius: 12px;
        padding: 16px;
        margin-bottom: 27px;

        .image {
            border-radius: 6px;
            height: 100%;
            width: 100%;
        }

        .info {
            padding-left: 16px;
            .name {
                font-weight: 700;
            }
            .details {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: #6b7280;
                margin-top: 6px;
                .icon {
                    color: transparent;
                }
            }
        }
    }

    .lessons {
        margin-bottom: 27px;
        border-radius: 12px;
        border: 1px solid #dddfe3;
        & > div:nth-child(1) > div:nth-child(1) {
            border-radius: 12px !important;
            background: #fff;
        }
        & > div:nth-child(1) > div:nth-child(1) > div {
            display: none !important;
        }
        & > div:nth-child(1) > div:nth-child(2) {
            border-bottom-left-radius: 12px !important;
            border-bottom-right-radius: 12px !important;
            background: #fff;
        }
        & > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
            padding-top: 0 !important;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .lesson {
                font-size: 15px;
                font-weight: 600;
            }
            .arrowDown {
                font-size: 20px;
                color: transparent;
            }
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 18px;
            .playName {
                display: flex;
                align-items: center;
                .play {
                    font-size: 18px;
                    margin-right: 12px;
                }
                .name {
                    font-size: 14px;
                }
            }
            .duration {
                font-size: 12px;
                color: #9ca3af;
            }
        }
    }
}
