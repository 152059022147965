.icon {
    font-size: 24px;
}

.instructor {
    margin-top: 17px;
    display: flex;
    align-items: center;
    .info {
        color: #fff;
        padding-left: 12px;
        .name {
            font-weight: 800;
            font-size: 20px;
        }
    }
}

.createCourse {
    margin-top: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 19px 12px 19px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);
    .info {
        line-height: 125%;
        .ready {
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
        }
        .create {
            color: #fff;
        }
    }
    .image {
        margin-left: auto;
        margin-right: 0;
    }
}

.panel {
    margin-top: 81px;
    color: #fff;
    .avatar {
        width: 150px;
        height: 150px;
        margin-bottom: 34px;
    }
    .title {
        font-weight: 600;
        font-size: 32px;
    }
    .description {
        margin-bottom: 34px;
    }
    .next {
        display: flex;
        justify-content: flex-end;
        .button {
            width: 56px;
            height: 56px;
        }
    }
}

.instructorBackground {
    background: linear-gradient(340.65deg, #283891 -17.17%, #92278f 127.08%);
    margin-left: -30px;
    margin-right: -30px;
    padding: 17px 30px 27px 30px;
    .personal {
        display: flex;
        align-items: center;

        .info {
            color: #fff;
            padding-left: 12px;
            line-height: 130%;
            .name {
                font-weight: 800;
                font-size: 20px;
            }
            .description {
                margin-top: 5px;
            }
        }
    }

    .details {
        margin-top: 19px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 19px 12px 19px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.05) !important;
        backdrop-filter: blur(2px);
        .content {
            line-height: 125%;
            color: #fff;
            .number {
                display: flex;
                justify-content: center;
                font-weight: 700;
            }
            .students {
                font-size: 12px;
            }
        }
        .line {
            width: 24px;
            height: 0px;

            border: 1px solid rgba(255, 255, 255, 0.1);
            transform: rotate(90deg);
        }
    }
}

.myCourses {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
        font-weight: 700;
        line-height: 150%;
    }
}

.card {
    border: 1px solid #dddfe3;
    border-radius: 12px;
    // To change card title padding
    & > div:nth-child(1) {
        padding: 16px;
    }
    // To change card description padding
    & > div:nth-child(2) {
        padding: 15px;
    }

    // Card title styles
    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        .image {
            border-radius: 6px;
            height: 70px;
            width: 70px;
        }

        .info {
            margin-left: 16px;
            .course {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 10px;
            }

            .seats {
                font-size: 12px;
                font-weight: 700;
                color: #6b7280;
            }
        }
    }

    // Card description styles
    .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .students {
            color: #6b7280;
            font-weight: 500;
            font-size: 13px;
            margin-right: 5px;
        }
        .status {
            font-size: 12px;
        }
    }
}
