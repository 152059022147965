.title {
    color: #fff;
    font-weight: 600;
}
.icon {
    font-size: 24px;
}

.panel {
    text-align: center;
    color: #fff;
    .polygons {
        display: flex;
        justify-content: space-around;
        padding: 0 15px;
        margin-top: 5px;
        .imageContainer {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 0;
            .credits {
                position: absolute;
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 60px;
                color: #fff;
                .mc {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 100%;
                    margin-top: 35px;
                }
            }
        }
        .polygon {
            width: 144px;
            margin-bottom: 16px;
        }
    }
    .mcs {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 16px;
    }
    .card {
        border: 1px solid rgba(255, 255, 255, 0.05) !important;

        border-radius: 12px;
        background: linear-gradient(106.73deg, rgba(255, 255, 255, 0.05) 20.32%, rgba(255, 255, 255, 0) 61.56%);
        backdrop-filter: blur(10px);
        .credit {
            padding: 12px;

            .image {
                border-radius: 6px;
                height: 100%;
                width: 100%;
            }

            .info {
                padding-left: 16px;
                .course {
                    font-weight: 700;
                    font-size: 14px;
                    color: #fff;
                    text-align: start;
                }
            }
            .imageContainer {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                .credits {
                    position: absolute;
                    font-family: 'Montserrat';
                    font-weight: 700;
                    font-size: 17px;
                    color: #fff;
                    .mc {
                        font-size: 9px;
                        font-weight: 500;
                        line-height: 100%;
                    }
                }

                .creditsContainer {
                    width: 42px;
                }
            }
        }
    }
    .badges {
        display: flex;
        justify-content: space-around;
        .polygon {
            width: 164px;
        }
        .course {
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin-top: 10px;
        }
    }
}
