.panel {
    padding-top: 70px;
    .textAlign {
        margin-left: auto;
        margin-right: auto;
        display: block;
        flex: none;
    }
    .title {
        margin-top: 44px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        flex: none;
    }
    .description {
        margin-top: 16px;
    }
    .buttons {
        margin-top: 90px;
    }
    .error {
        color: red;
        text-align: center;
    }
}
