.layout {
    min-height: 100vh;

    .sider {
        background: linear-gradient(293.84deg, #92278f -15.44%, #283891 112.64%) !important;

        .title {
            text-align: center;
            white-space: nowrap;
            line-height: 60px;
            height: 60px;

            .logo {
                margin-right: 10px;
                width: 25px;
            }
            .logoTitle {
                width: 110px;
            }
        }

        &.collapsed {
            margin-right: 0;
        }

        .menu {
            border-inline-end: 0 none !important;
            padding: 0 10px;
            margin-top: 12px;
            background: transparent !important;

            li {
                height: 50px;
                line-height: 50px;
                margin-bottom: 8px;

                .icon {
                    svg {
                        font-size: 20px !important;
                        color: transparent !important;
                    }
                }
            }
        }
    }

    .header {
        background: #fff;
        padding: 0 0 0 16px;
        display: flex;
        align-items: center;
        height: 60px;

        .title {
            flex: 1;
            font-size: 20px;

            font-weight: 500;
            color: #45464e;
        }

        .breadcrumb {
            flex: 1;
        }

        .avatar {
            justify-content: center;
            background-color: #1890ff;
            color: #fff;
            cursor: pointer;
        }

        .notifications {
            margin-right: 12px;
            svg {
                font-size: 24px;
            }
        }
    }

    .content {
        .main {
            background: #fff;
            padding: 24px;
            min-height: 500px;
            margin: 0 16px;
            margin-top: 16px;
            border-radius: 12px;
        }
    }

    .footer {
        padding-left: 20px;
        padding-right: 20px;
        color: rgba(139, 141, 151, 1);
        .links {
            flex-grow: 1;
            .link {
                color: rgba(139, 141, 151, 1);
            }
        }
        .copyright {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.layoutMobile {
    .header {
        padding: 19px 17px 19px 17px;
        height: auto;
        line-height: 0;
        background: #fff;

        .topHeader {
            display: flex;
            align-items: center;

            .left {
                float: left;
                .drawerIcon {
                    margin-right: 7px;
                    font-size: 16px;
                    line-height: 7px;
                }
            }

            .centerHeader {
                margin-left: auto;
                margin-right: 0;
            }

            .rightHeader {
                margin-left: auto;
            }
        }

        .subHeader {
            margin-top: 50px;
            display: block;
        }
    }

    .headerLilac {
        padding: 19px 17px 19px 17px;
        height: auto;
        line-height: 0;
        background: linear-gradient(293.84deg, #92278f -15.44%, #283891 112.64%);

        .topHeader {
            display: flex;
            align-items: center;

            .left {
                float: left;
                .drawerIcon {
                    margin-right: 7px;
                    font-size: 16px;
                    line-height: 7px;
                }
            }

            .centerHeader {
                margin-left: auto;
                margin-right: 0;
            }

            .rightHeader {
                margin-left: auto;
            }
        }

        .subHeader {
            margin-top: 50px;
            display: block;
        }
    }

    .content {
        background: #fff;
        .main {
            min-height: 100vh;
            padding: 0 25px 25px 25px;
        }
    }

    .lilacContent {
        background: linear-gradient(340.65deg, #283891 -17.17%, #92278f 127.08%);
        .main {
            min-height: 100vh;
            padding: 0 25px 0 25px;
        }
    }
}

.layoutMobileCredits {
    background: linear-gradient(293.84deg, #92278f -15.44%, #283891 112.64%);
    .header {
        padding: 19px 17px 19px 17px;
        height: auto;
        line-height: 0;
        background: none;

        .topHeader {
            display: flex;
            align-items: center;

            .left {
                float: left;
                .drawerIcon {
                    margin-right: 7px;
                    font-size: 16px;
                    line-height: 7px;
                }
            }

            .centerHeader {
                margin-left: auto;
                margin-right: 0;
            }

            .rightHeader {
                margin-left: auto;
            }
        }

        .subHeader {
            margin-top: 50px;
            display: block;
        }
    }

    .content {
        .main {
            min-height: 100vh;
            padding: 0 25px 0 25px;
        }
    }
}

.mobileMenu {
    background-color: transparent;
    font-size: 18px;
    li {
        padding-left: 4px !important;
        .icon {
            color: transparent !important;
            margin-right: 9px;
            font-size: 22px !important;
        }
    }
}

.menuHeader {
    background: transparent !important;
    color: #fff;
    margin-bottom: 50px;
    .profile {
        display: flex;
        align-items: center;
        .avatar {
            width: 68px;
            height: 68px;
        }
        .profileInfo {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            .name {
                font-weight: 700;
            }
            .rol {
                margin-top: 4px;
                color: rgba(255, 255, 255, 0.5);
            }
        }
        .logout {
            font-size: 20px;
            color: transparent;
            margin-left: auto;
        }
    }
    .becomeInstructor {
        color: #fff;
        background: transparent;
        width: 100%;
        margin-top: 25px;
        padding-top: 17px;
        padding-bottom: 17px;
        height: 56px;
        border: 1px solid #ffffff;
        border-radius: 12px;
    }
}

.drawer {
    background: linear-gradient(293.84deg, #92278f -15.44%, #283891 112.64%) !important;
    .top {
        padding: 19px 0 19px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .drawerClose {
        font-size: 20px;
        line-height: 7px;
    }
}
