.ant-tabs-tab {
    padding: 4px 0 !important;
}

.ant-tabs-tab div {
    padding: 8px;
}

.ant-tabs-tab.ant-tabs-tab-active div {
color: #000 !important;
background: #fff;
border-radius: 6px;
}

.ant-tabs-nav-wrap {
    justify-content: center;
}