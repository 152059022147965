.panel {
    padding-top: 70px;
    .avatar {
        width: 100%;
        padding-top: 100%;
        background: rgba(237, 239, 240, 1);
    }

    .title {
        margin-top: 44px;
        text-align: center;
    }
    .description {
        margin-top: 16px;
        text-align: center;
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 90px;
        .next {
            width: 50px;
            height: 50px;
        }
    }
}
