.icon {
    font-size: 24px;
}

.studentBackground {
    background: linear-gradient(340.65deg, #283891 -17.17%, #92278f 127.08%);
    margin-left: -30px;
    margin-right: -30px;
    padding: 17px 30px 27px 30px;
    .personal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .information {
            display: flex;
            align-items: center;
            .info {
                color: #fff;
                padding-left: 12px;
                line-height: 130%;
                .name {
                    font-weight: 800;
                    font-size: 20px;
                }
                .description {
                    margin-top: 5px;
                }
            }
        }
        .cup {
            padding-left: 19px;
        }
    }

    .details {
        margin-top: 19px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 11px 10px 19px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.05) !important;
        backdrop-filter: blur(2px);
        color: #fff;
        letter-spacing: 0.2px;
        .coursePerformance {
            width: 100%;
            .performance {
                margin-top: 8px;
                font-weight: 700;
                line-height: 150%;
            }
            .percentage {
                margin-top: 13px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                line-height: 160%;
            }
        }
        .progressBar {
            margin-top: 3px;
            & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
                background-color: #25d076;
            }
        }
        .imageContainer {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            .credits {
                position: absolute;
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 33.446px;
                line-height: 100%;
                color: #fff;
                margin-left: 15px;
                .mc {
                    font-weight: 500;
                    font-size: 13.2836px;
                    line-height: 100%;
                }
            }
            .hexagon {
                width: 84px;
                height: 84px;
                margin-left: 15px;
            }
        }
    }
}

.submissions {
    margin-top: 20px;
    font-weight: 700;
    line-height: 150%;
    color: #111827;
}

.collapse {
    margin-top: 28px;
    background: #ffffff;
    border: 1px solid #eeeff2;
    border-radius: 12px;
    .header {
        line-height: 160%;
        .one {
            font-weight: 700;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 95%;
        }
        .two {
            font-weight: 700;
            font-size: 14px;
            color: #6b7280;
        }
        .three {
            margin-top: 10px;
            font-size: 12px;
            color: #6b7280;
        }
    }
    .file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 11px;
        .image {
            display: flex;
            align-items: center;
            .folder {
                font-size: 35px;
            }
            .fileName {
                margin-left: 8px;

                .name {
                    font-weight: 700;
                    font-size: 14px;
                }
                .size {
                    font-size: 12px;
                    color: #6b7280;
                }
            }
        }
        .download {
            font-size: 15px;
            color: transparent;
        }
    }
    .grade {
        margin-top: 7px;
        background: #f8f8f8;
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: -16px;
        padding: 7px 13px;
        .content {
            display: flex;
            align-items: center;
            .smallImageContainer {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .number {
                    position: absolute;
                    font-size: 22px;
                    font-weight: 700;
                    color: #fff;
                }
                .hexagonSmall {
                    width: 53px;
                    height: 53px;
                }
            }
            .gradeText {
                margin-left: 5px;
                .title {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 160%;
                }
                .date {
                    font-size: 12px;
                    line-height: 160%;
                    color: #6b7280;
                }
            }
        }
        .message {
            margin-top: 20px;
            background: #fff;
            border-radius: 0px 12px 12px 12px;
            padding: 12px;
            gap: 8px;
        }
    }
}
