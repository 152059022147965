.panel {
    .tabs {
        margin-top: 25px;
        // To change tabs font, margin, background, hidde bottom line
        & > div:nth-child(1) {
            background: #f9fafb;
            border-radius: 10px;
            color: #9ca3af;
            font-weight: 600;
        }

        & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(1) {
            margin: 0;
            padding-left: 4px !important;
        }
        & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(2) {
            margin: 0;
        }
        & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(3) {
            margin: 0;
        }
        & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(4) {
            margin: 0;
        }
        & > div > div > div > div:nth-of-type(5) {
            background: none;
        }

        & > div:nth-child(1) {
            &::before {
                border-bottom: none;
            }
        }

        .allCard {
            border: 1px solid #dddfe3;
            border-radius: 12px;
            // To change card title padding
            & > div:nth-child(1) {
                padding: 16px;
            }
            // To change card description padding
            & > div:nth-child(2) {
                padding: 15px;
            }

            // Card title styles
            .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                .image {
                    border-radius: 6px;
                    height: 70px;
                    width: 70px;
                }

                .info {
                    margin-left: 16px;
                    .course {
                        font-weight: 700;
                        font-size: 16px;
                        margin-bottom: 10px;
                    }

                    .author {
                        font-size: 12px;
                        font-weight: 700;
                        color: #6b7280;
                    }
                }
            }

            // Card description styles
            .details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .marketValue {
                    color: #6b7280;
                    font-weight: 500;
                    font-size: 13px;
                    margin-right: 5px;
                }
                .credits {
                    font-size: 13px;
                }
                .status {
                    font-size: 12px;
                }
            }
        }

        .progressCard {
            margin-bottom: 24px;
            // Card title styles
            .titleHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 13px;
                .title {
                    font-size: 20px;
                    font-weight: 800;
                }
                .discord {
                    font-weight: 600;
                    font-size: 14px;
                    color: #92278f;
                }
            }

            // Card description styles
            .details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 13px;
                font-size: 12px;
                color: #6b7280;
            }

            .progressBar {
                margin-top: 3px;
                & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
                    background-color: #25d076;
                }
            }
        }

        .ownedCard {
            margin-bottom: 24px;
            // Card title styles
            .titleHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 13px;
                .title {
                    font-size: 20px;
                    font-weight: 800;
                }
                .start {
                    font-weight: 600;
                    font-size: 14px;
                    color: #25d076;
                }
            }

            .author {
                margin-top: 5px;
                font-weight: 600;
                font-size: 14px;
                color: #111827;
            }
        }

        .registeredCard {
            margin-bottom: 24px;
            // Card title styles
            .titleHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 13px;
                .title {
                    font-size: 20px;
                    font-weight: 800;
                }
                .start {
                    font-weight: 600;
                    font-size: 14px;
                    color: #25d076;
                }
            }

            .author {
                margin-top: 5px;
                font-weight: 600;
                font-size: 14px;
                color: #111827;
            }
        }
    }
}

.myCourses {
    font-family: 'Montserrat';
    font-weight: 800;
    text-align: center;
    color: #fff;
    font-size: 21px;
}

.imageContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .credits {
        position: absolute;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
    }
}
