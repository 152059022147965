.featured {
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: 700;
}

.allCourses {
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: 700;
    .filter {
        float: right;
        & > span:nth-of-type(1) {
            color: transparent;
        }
        & > span:nth-of-type(2) {
            color: #6b7280;
        }
    }
}

.myCourses {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #fff;
}

.search {
    & > span > span {
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-radius: 12px !important;
        height: 56px;

        input {
            color: rgba(255, 255, 255, 0.5);
            background-color: transparent !important;
            background-image: url('../../resources/images/search-icon.svg');
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 30px !important;
        }
        input::placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-size: 14px;
        }
    }

    // To remove default search icon in the right
    & > span > span:nth-of-type(2) {
        left: 0;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            & > span {
                display: none !important;
            }
        }
    }
}

.card {
    border: 1px solid #f3f4f6 !important;
    border-radius: 12px;
    .course {
        padding: 12px;

        .image {
            border-radius: 6px;
            height: auto;
            width: 100%;
        }

        .info {
            padding-left: 16px;
            .title {
                font-weight: 700;
                font-size: 16px;
            }
            .details {
                display: flex;
                justify-content: space-between;
                font-weight: 700;
                font-size: 12px;
                color: #6b7280;
            }
        }
    }
}

.featuredCard {
    .image {
        border-radius: 12px;
        width: 100%;
    }

    .title {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .details {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        font-size: 12px;
        color: #6b7280;
    }
}
