.title {
    font-family: 'Montserrat';
    font-weight: 800;
    text-align: center;
    margin-bottom: 25px;
}

.buttons {
    margin-top: 90px;
}
