.icon {
    font-size: 24px;

    &.dots {
        padding-left: 11px;
    }
}

.title {
    font-family: 'Montserrat';
    font-weight: 700;
    text-align: center;
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
}

.tabs {
    margin-top: 25px;
    // To change tabs font, margin, background, hidde bottom line
    & > div:nth-child(1) {
        background: #f9fafb;
        border-radius: 10px;
        color: #9ca3af;
        font-weight: 600;
    }

    & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(1) {
        margin: 0;
        padding-left: 4px !important;
    }
    & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(2) {
        margin: 0;
    }
    & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(3) {
        margin: 0;
    }
    & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-of-type(4) {
        background: none;
    }

    & > div:nth-child(1) {
        &::before {
            border-bottom: none;
        }
    }
}

.filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .allStudents {
        font-weight: 700;
    }
    .button {
        color: #6b7280;
        & > span:nth-of-type(1) {
            margin-right: 9px;
            color: transparent;
        }
    }
}

.studentsList {
    margin-top: 30px;

    .student {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border: 1px solid #f3f4f6 !important;
        border-radius: 12px;
        padding: 7px;

        .personal {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .avatar {
                height: 56px;
                width: 56px;
            }

            .info {
                padding-left: 16px;
                .name {
                    font-weight: 700;
                }
                .lastSeen {
                    font-size: 13px;
                    color: #6b7280;
                }
            }
        }
        .tag {
            padding: 4px 12px;
            border-radius: 6px;
            font-weight: 700;
            color: #23b066;
            background: #c0f5da;
        }
    }
}

.assignmentsList {
    margin-top: 30px;

    .card {
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
            .name {
                font-size: 14px;
                line-height: 160%;
            }
            .description {
                font-size: 12px;
                color: #6b7280;
            }
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .file {
                display: flex;
                align-items: center;
                .fileName {
                    margin-left: 8px;
                    font-size: 12px;
                    line-height: 160%;
                    color: #6b7280;
                }
            }
            .download {
                font-size: 20px;
                color: transparent;
            }
        }
        & > div:nth-child(2) {
            padding: 10px 16px;
        }
    }
}

.filterCurriculum {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
    .pencil {
        color: transparent;
        font-size: 15px;
    }
}

.curriculum {
    margin-bottom: 16px;
    border-radius: 12px;
    border: 1px solid #dddfe3;
    & > div:nth-child(1) > div:nth-child(1) {
        border-radius: 12px !important;
        background: #fff;
    }
    & > div:nth-child(1) > div:nth-child(1) > div {
        display: none !important;
    }
    & > div:nth-child(1) > div:nth-child(2) {
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        background: #fff;
    }
    & > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
        padding-top: 0 !important;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .lesson {
            font-size: 15px;
            font-weight: 600;
        }
        .arrowDown {
            font-size: 20px;
            color: transparent;
        }
    }
    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 18px;
        .playName {
            display: flex;
            align-items: center;
            .play {
                font-size: 18px;
                margin-right: 12px;
            }
            .name {
                font-size: 14px;
            }
        }
        .duration {
            font-size: 12px;
            color: #9ca3af;
        }
    }
}
